import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import QRCodeStyling from "qr-code-styling";

const qrCode = new QRCodeStyling({
  width: 256,
  height: 256,
  image: "logo-very-smal.png",
  dotsOptions: {
    color: "#000000",
    type: "rounded",
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 5,
  },
});

export default function App() {
    const [url, setUrl] = useState("GCN001");
  const [fileExt, setFileExt] = useState("png");
  const ref = useRef(null);

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    let strTemplate = `https://data.tqc.gov.vn/index.html?gcn=${url}`;
    qrCode.update({
      data: strTemplate,
    });
  }, [url]);

  const onUrlChange = (event) => {
    event.preventDefault();
    setUrl(event.target.value);
  };

  const onExtensionChange = (event) => {
    setFileExt(event.target.value);
  };

  const onDownloadClick = () => {
    // qrCode.download({
    //   extension: fileExt,
    // });
    // Tạo một thẻ a (đường dẫn tải về) tạm thời
    const link = document.createElement("a");
    // Tạo URL của ảnh QR bằng cách sử dụng ref để lấy nội dung của thẻ div
    const qrImageBase64 = ref.current.firstChild.toDataURL("image/png");
    // Gán URL và tên file cho thẻ a
    link.href = qrImageBase64;
    link.download = `${urlInput.value}_QR.${fileExt}`;
    // Triggle sự kiện click
    link.click();
  };

  return (
    <div className="container mt-5">
      <h1 className="text-uppercase text-center mb-4">
        Tạo mã QR cho GCN Hợp Quy - tqc
      </h1>
      <div className="row">
        <div className="col-md-6">
          <label htmlFor="urlInput" className="form-label font-weight-bold">
            Số GCN Hợp quy:
          </label>
          <input
            type="text"
            id="urlInput"
            className="form-control mb-3"
            value={url}
            onChange={onUrlChange}
          />
        </div>
        <div className="col-md-3">
          <label htmlFor="extSelect" className="form-label font-weight-bold">
            Chọn định dạng:
          </label>
          <select
            id="extSelect"
            className="form-select mb-3"
            onChange={onExtensionChange}
            value={fileExt}
          >
            <option value="png">PNG</option>
            <option value="jpeg">JPEG</option>
            <option value="webp">WEBP</option>
          </select>
        </div>
        <div className="col-md-3">
          <label className="invisible">Download</label>
          <button className="btn btn-primary w-100" onClick={onDownloadClick}>
            Download
          </button>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <label className="form-label font-weight-bold">Mã QR:</label>
          <div ref={ref} />
        </div>
      </div>
    </div>
  );
}
